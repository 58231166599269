import { graphql } from 'gatsby';
import React from 'react';
import NewsTemplate from '../components/NewsList/NewsTemplate';
import Layout from '../components/layout';
import Seo from '../components/seo';
import CallToAction from '../components/CallToAction/CallToAction';

const BlogListingTemplate = ({
	location,
	data: { posts, searchIndex },
	pageContext,
}) => {
	return (
		<Layout location={location}>
			<article>
				<div className="container md:mx-auto max-w-page py-4 md:py-8">
					<h1 className="text-3xl md:text-4xl font-alegreya">Writing</h1>
					<NewsTemplate
						posts={posts}
						searchIndex={searchIndex}
						pageContext={pageContext}
						location={location}
					/>
				</div>
			</article>
			<CallToAction/>
		</Layout>
	);
};

export const Head = () => (
	<Seo title="Acceptance Counselling | Writing" />
)

export const query = graphql`
	query ($skip: Int!, $limit: Int!) {
		searchIndex: localSearchPosts {
			index
			store
		}
		posts: allStoryblokEntry(
			filter: { field_component: { eq: "blogpost" } }
			sort: { fields: field_published_string, order: ASC }
			limit: $limit
			skip: $skip
		) {
			totalCount
			edges {
				node {
					...blogpost
				}
			}
		}
	}
`;

export default BlogListingTemplate;
