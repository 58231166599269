import React from 'react';
import Pagination from '../Pagination/Pagination';
import NewsList from './NewsList';
import { useFlexSearch } from 'react-use-flexsearch';
import { unFlattenResults } from '../helpers';

const NewsTemplate = ({
	siteName,
	location,
	posts,
	searchIndex,
	pageContext,
}) => {
	const { index, store } = searchIndex;

	const { search } = location;
	const querySearch = new URLSearchParams(search).get('s');
	const queriedResults = useFlexSearch(querySearch, index, store);

	const results = querySearch
		? unFlattenResults(queriedResults)
		: posts.edges;

	return (
		<>
			<NewsList
				siteName={siteName}
				posts={results}
				pageContext={pageContext}
				hasSearch
				showDates
				querySearch={querySearch}
				pages={pageContext.numberOfPages}
				currentPage={pageContext.currentPage}
			/>

			{((pageContext && !querySearch ) ||
				(pageContext && !querySearch === 'all')) && (
				<Pagination pageContext={pageContext} slug="blog" />
			)}
		</>
	);
};

export default NewsTemplate;
