import { Link } from 'gatsby';
import React from 'react';

const Pagination = ({ pageContext, slug }) => {
	const { currentPage, numberOfPages } = pageContext;
	const isFirst = currentPage === 1;
	const isLast = currentPage === numberOfPages;
	const prevPage =
		currentPage - 1 === 1
			? `/${slug}/`
			: `/${slug}/${(currentPage - 1).toString()}`;
	const nextPage = `/${slug}/${(currentPage + 1).toString()}`;

	return (
		<section className="pt-4 md:pt-8 mt-4 md:mt-8 border-t-2 border-gray-300">
			<div className="grid grid-cols-3 text-sm md:text-base w-max mx-auto ">
				<div className="justify-self-end pr-2">
					{!isFirst && (
						<Link to={prevPage} rel="prev" className="font-semibold text-pink-700 md:no-underline md:hover:underline flex-shrink-0">
							<span>&lt; Previous</span>
						</Link>
					)}
				</div>
				<div className="flex justify-center text-pink-700">
					{Array.from({ length: numberOfPages }, (_, i) => (
						<Link
							key={`pagination-number${i + 1}`}
							to={`/${slug}/${i === 0 ? '' : i + 1}`}
							className="font-semibold md:no-underline md:hover:underline px-2"
							activeClassName="bg-pink-700 text-white md:hover:no-underline"
						>
							{i + 1}
						</Link>
					))}
				</div>
				<div className="justify-self-start pl-2">
					{!isLast && (
						<Link to={nextPage} rel="next" className="font-semibold text-pink-700 md:no-underline md:hover:underline flex-shrink-0">
							Next &gt;
						</Link>
					)}
				</div>
			</div>
		</section>
	);
};

export default Pagination;
